body {
  margin: 0;
  font-family: 'M PLUS 1', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  min-width: 375px;
}

#root {
  background-color: #ffffff;
  font-family: 'M PLUS 1', sans-serif;
}

a {
  font-family: 'M PLUS 1', sans-serif;
}

label {
  font-family: 'M PLUS 1', sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

p {
  word-break: break-word;
}

.qr-code-label {
  padding: 5px 10px 0 0;
  text-align: right;
}

.qr-code-help-text {
  font-size: 0.875em;
  color: #6c757d
}

.pdf-viewer-center {
  .react-pdf__Page__canvas {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.konvajs-content-custom {
  .konvajs-content {
    display: block;
    margin: auto;
  }
}

.pdf-viewer-center {
  .react-pdf__Page__canvas{
    border: 1px solid #ddd !important;
    user-select: none,
  }
}
